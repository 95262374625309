@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

html {
  margin: 0;
  padding: 0;
}
body {
  padding: 0;
  margin: 0;
  overscroll-behavior-y: none;
  /* background-color: #ced3d5; */
  font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

